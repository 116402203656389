/**
 * Get the number of days in a given month and year
 * @param {string} month - Month name (Jan, Feb, etc.)
 * @param {string|number} year - Year (YYYY)
 * @returns {number} Number of days in the month
 */
export const getDaysInMonth = (month, year) => {
  const monthMap = {
    'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
    'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
  };
  const monthNum = monthMap[month];
  return new Date(parseInt(year), monthNum + 1, 0).getDate();
};

/**
 * Validate and adjust day to be valid for given month/year
 * @param {string|number} day - Day of month
 * @param {string} month - Month name (Jan, Feb, etc.)
 * @param {string|number} year - Year (YYYY)
 * @returns {string} Valid day padded with leading zero if needed
 */
export const validateAndAdjustDay = (day, month, year) => {
  const daysInMonth = getDaysInMonth(month, year);
  return Math.min(parseInt(day), daysInMonth).toString().padStart(2, "0");
};

/**
 * Get array of available days for given month/year
 * @param {string} month - Month name (Jan, Feb, etc.)
 * @param {string|number} year - Year (YYYY)
 * @returns {string[]} Array of days padded with leading zeros
 */
export const getAvailableDays = (month, year) => {
  const daysInMonth = getDaysInMonth(month, year);
  return [...Array(daysInMonth)].map((_, i) => 
    (i + 1).toString().padStart(2, "0")
  );
};

/**
 * Convert date object to string format
 * @param {Object} dateObj - Date object with year, month, day properties
 * @returns {string} Date string in YYYY-MM-DD format
 */
export const dateToString = (dateObj) => {
  return `${dateObj.year}-${dateObj.month}-${dateObj.day}`;
};

/**
 * Get month number (0-11) from month name
 * @param {string} monthName - Month name (Jan, Feb, etc.)
 * @returns {number} Month number (0-11)
 */
export const getMonthNumber = (monthName) => {
  const monthMap = {
    'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
    'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
  };
  return monthMap[monthName];
};