import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Alert,
  Box,
  Button,
  Skeleton,
  Snackbar,
  Stack,
  Table,
  Typography,
} from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Container from '../components/Container';
import DragAndDrop from '../components/DragAndDrop';
import DuplicateFileModal from '../components/DuplicateFileModal';
import FileTable from '../components/FileTable';
import TipPopUp from '../components/TipsPopUp';
import { TableLoadingSkeleton } from '../components/LoadingSkeletons';
import { NoFiles} from '../components/EmptyStates.js';
import CourseCheck from '../components/CourseCheck';

const FileTypeAlert = ({ onClose }) => {
    return (
        <Alert
          variant="soft"
          color="neutral"
          startDecorator={<InfoOutlinedIcon />}
          endDecorator={
            <Button variant="plain" onClick={onClose}>
              Close Tip
            </Button>
          }
          sx={{ mb: 2 }}
          role="alert"
          aria-live="polite"
        >
          <strong>Tip:</strong> PDF and audio files may take longer to process (roughly 10-15 seconds per minute of audio or page of pdf).
        </Alert>
  );
};

export default function Files({ headerRef }) {
    const [showTips, setShowTips] = useState(false);
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const hasLoadingFiles = useRef(false);
    const [alertFiles, setAlertFiles] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [showCompletionSnackbar, setShowCompletionSnackbar] = useState(false);
    const [processingInitiated, setProcessingInitiated] = useState(false);
    const eventSourceRef = useRef(null);
    const [eventSourceConnected, setEventSourceConnected] = useState(false);

    const connectToEventSource = () => {
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
        }
    
        if (process.env.REACT_APP_API_URL) {
            eventSourceRef.current = new EventSource(`${process.env.REACT_APP_API_URL}/stream/file_updates`, { 
                withCredentials: true 
            });
        } else {
            eventSourceRef.current = new EventSource(`${window.location.origin}/stream/file_updates`);
        }
        
        eventSourceRef.current.onopen = function() {
            setEventSourceConnected(true);
        }; 

        eventSourceRef.current.onmessage = function(event) {
            const data = JSON.parse(event.data)

            if (data["type"] == "update") {      
                setFiles(prevFiles =>
                    prevFiles.map(file =>
                        file.file_id === data["file_id"]
                            ? { ...file, ...data["updated_fields"] }
                            : file
                    )
                );

            }
        };

        eventSourceRef.current.onerror = function(error) {
            eventSourceRef.current.close();
            axios.post('/api/unsubscribe_file_updates');
            setEventSourceConnected(false);
        };
    }

    const reconnectEventSource = () => {
        setEventSourceConnected(prevState => {
            if (!prevState) {
                connectToEventSource();
                return true;
            }
            return prevState;
        });
    }

    useEffect(() => {
        document.title = "Files - All Day TA";
        window.addEventListener("click", reconnectEventSource);
        window.addEventListener("keydown", reconnectEventSource);

        return () => {
            window.removeEventListener("click", reconnectEventSource);
            window.removeEventListener("keydown", reconnectEventSource);
        };
    }, []);

    useEffect(() => {
        connectToEventSource();
        
        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
                axios.post('/api/unsubscribe_file_updates');
            }
            setEventSourceConnected(false);
        };
    }, [headerRef.current])

    const shouldShowTips = (files) => {
        return files.length === 0 || 
               location.state?.newCourseCreated;
    };

    const fetchStatus = useCallback(async () => {
        //console.log("Fetching status here...");
        setIsLoading(true);
        try {
            const response = await axios.get('/api/course_files_retrieve');
            if (response.data) {
                //console.log(response.data);
                const convertedFiles = convertToDisplay(response.data);
                setFiles(convertedFiles);
                setShowTips(shouldShowTips(convertedFiles));
            }
        } catch (error) {
            //console.error('Error fetching course files:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchStatus();
    }, [fetchStatus]);

    useEffect(() => {
        if (headerRef.current) {
            headerRef.current.setOnCourseChange(fetchStatus);
        }
    }, [headerRef, fetchStatus]);

    // Convert data fetched into a suitable form before setting it as files
    const convertToDisplay = (data) => {
        //console.log("Currently converting the data to be able to better display it...");
        const convertedData = data.map((item, index) => {
            return {
                course_id: item.course_id,
                file_id: item.file_id,
                org_id: item.org_id,
                id: index + 1,
                title: item.title,
                isactive: item.isactive,
                isoptional: item.isoptional,
                status: item.status,
            };
        });
        //console.log(convertedData);
        return convertedData;
    };

    useEffect(() => {
        hasLoadingFiles.current = files.some(file => file.status === 'Loading');
        //console.log('Has loading files:', hasLoadingFiles.current);
    }, [files]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasLoadingFiles.current) {
                //console.log('Preventing navigation due to loading files');
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    
    useEffect(() => {
        if (location.state?.newCourseCreated) {
            setShowTips(true);
            // Clear the newCourseCreated flag after showing tips
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location, navigate]);


    const handleCloseTipsBox = () => {
        setShowTips(false);
    };

    const handleOpenTipsBox = () => {
        setShowTips(true);
    };

    // PDF & audio file length alert functions
    const checkForAlertFiles = useCallback((newFiles) => {
        const alertTypes = ['.pdf', '.mp3', '.mp4', '.wav', '.ogg', '.m4a'];
        const newAlertFiles = newFiles.filter(file => 
            alertTypes.some(type => file.title.toLowerCase().endsWith(type))
        );

        if (newAlertFiles.length > 0) {
            setShowAlert(true);
        } 
    }, []);

    useEffect(() => {
        checkForAlertFiles(files);
    }, [files, checkForAlertFiles]);

    //Functions for Snackbar that it's okay to leave
    useEffect(() => {
        const allFilesFinished = files.every(file => 
            file.status === 'Processing' || 
            file.status === 'Complete' || 
            file.status === 'Error' ||
            file.status === 'success'
        );

        const anyFileProcessing = files.some(file => 
            file.status === 'Loading' ||
            file.status === 'Replacing...'
        );

        if (processingInitiated && allFilesFinished && files.length > 0) {
            setShowCompletionSnackbar(true);
            setProcessingInitiated(false);
        }

        // Set processingInitiated to true if any file is still processing
        if (anyFileProcessing) {
            setProcessingInitiated(true);
        }
    }, [files]);

    const handleCloseSnackbar = () => {
        setShowCompletionSnackbar(false);
    };

    return (
        <Container>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ flexWrap: 'wrap' }}
            >   
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                > 
                    <Typography marginRight="1.5rem" level="h1">
                        Files
                    </Typography>
                </Stack>
                {!showTips && (
                <Button
                    variant="plain"
                    color="primary"
                    size="md"
                    endDecorator={<InfoOutlinedIcon />}
                    onClick={handleOpenTipsBox}
                    >
                    Tips for Getting Started
                </Button>
                )}
            </Stack>
            <CourseCheck>
            {showTips && <TipPopUp onClose={handleCloseTipsBox}/>}
            <DragAndDrop setFiles={setFiles} refresh={fetchStatus}/>
            {showAlert && (
                <FileTypeAlert 
                    files={files} 
                    onClose={() => setShowAlert(false)} 
                />
            )}
            {isLoading ? (
                <TableLoadingSkeleton />
            ) : files.length > 0 ? (
                <FileTable files={files} refresh={fetchStatus} setFiles={setFiles}/>
            ) : (
                <NoFiles />
            )}
            </CourseCheck>
            <Snackbar
                variant="solid"
                color="primary"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={showCompletionSnackbar}
                onClose={() => setShowCompletionSnackbar(false)}
                role="alert"
                aria-live="polite"
                startDecorator={<CheckCircleIcon />}
                endDecorator={
                  <Button
                    variant="solid"
                    size="sm"
                    onClick={handleCloseSnackbar}
                  >
                    Dismiss
                  </Button>
                }
              >
                All files are now processing in the background, it's safe to navigate away!
            </Snackbar>
        </Container>
    );
}