import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  ChipDelete,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Option,
  Select,
  Sheet,
  Stack,
  Typography
} from '@mui/joy';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import CloseIcon from '@mui/icons-material/Close';
import Container from '../components/Container';

const generateQuestionKey = (question) => {
  return `q-${question.course_id}-${question.question_id}-${question.timestamp}`;
};

// Separate QuestionsList component
const QuestionsList = React.memo(({ filteredQuestions, questions }) => {
  return (
    <Card variant="outlined">
      <Typography level="h4" sx={{ mb: 2 }}>
        Questions ({filteredQuestions.length})
        {filteredQuestions.length !== questions.length && (
          <Typography level="body-sm" sx={{ color: 'neutral.500' }}>
            ({questions.length - filteredQuestions.length} questions filtered out)
          </Typography>
        )}
      </Typography>
      <Box sx={{ mt: 2 }}>
        {filteredQuestions.map((question) => (
          <QuestionCard key={generateQuestionKey(question)} question={question} />
        ))}
      </Box>
    </Card>
  );
});

// Separate QuestionCard component to handle individual question display
const QuestionCard = React.memo(({ question }) => {
  const questionKey = generateQuestionKey(question);
  
  return (
    <Card variant="soft" sx={{ mb: 2 }}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ display: 'flex', gap: 1, mb: 1, flexWrap: 'wrap' }}>
              <Typography level="title-md">{question.course_name}</Typography>
              <Chip 
                size="md" 
                startDecorator={<LanguageRoundedIcon />}
              >
                {question.language}
              </Chip>
              {question.is_syllabus && (
                <Chip 
                  size="md" 
                  color="primary" 
                  startDecorator={<MenuBookRoundedIcon />}
                >
                  Syllabus Question
                </Chip>
              )}
              {question.response_rating !== null && (
                <Chip 
                  size="md" 
                  color={question.response_rating ? "success" : "danger"}
                  startDecorator={question.response_rating ? 
                    <CheckRoundedIcon /> : 
                    <CloseRoundedIcon />
                  }
                >
                  {question.response_rating ? "Helpful" : "Not Helpful"}
                </Chip>
              )}
              {question.is_follow_up && (
                <Chip 
                  size="md" 
                  color="success" 
                  startDecorator={<ForumRoundedIcon />}
                >
                  Follow-up
                </Chip>
              )}
              <Chip 
                size="md" 
                startDecorator={<SmartToyRoundedIcon />}
              >
                {question.llm_used}
              </Chip>
              <Chip size="md">
                Similarity: {(question.similarity_score * 100).toFixed(1)}%
              </Chip>
            </Box>
            <Typography level="body-sm" sx={{ mb: 1 }}>
              {new Date(question.timestamp).toLocaleString()}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} md={6}>
          <Typography level="title-md" sx={{ mb: 1 }}>Question:</Typography>
          <Typography level="body-md">{question.question}</Typography>
        </Grid>
        <Grid xs={12} md={6}>
          <Typography level="title-md" sx={{ mb: 1 }}>Answer:</Typography>
          <Typography level="body-md">{question.answer}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
});

export default function Analytics() {
  const [filters, setFilters] = useState({
    org_id: '',
    admin_id: '',
    course_id: '',
    start_time: '',
    end_time: '',
    excluded_ips: []
  });
  
  const [filterOptions, setFilterOptions] = useState({
    organizations: [],
    admins: [],
    courses: []
  });
  
  const [questions, setQuestions] = useState([]);
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newIpAddress, setNewIpAddress] = useState('');

  // Add IP address to excluded list
  const handleAddIpAddress = () => {
    if (!newIpAddress) return;

    if (filters.excluded_ips.includes(newIpAddress)) {
      setError("IP address already in exclusion list");
      return;
    }

    setFilters(prev => ({
      ...prev,
      excluded_ips: [...prev.excluded_ips, newIpAddress]
    }));
    setNewIpAddress('');
    setError(null);
  };

  // Remove IP address from excluded list
  const handleRemoveIpAddress = (ipToRemove) => {
    setFilters(prev => ({
      ...prev,
      excluded_ips: prev.excluded_ips.filter(ip => ip !== ipToRemove)
    }));
  };

  // Updated filtering function with proper IP check
  const getFilteredQuestions = (questions, excludedIps) => {
    return questions.filter(question => {
      // Make sure ipaddress exists and isn't in the excluded list
      return !excludedIps.includes(question.ipaddress);
    });
  };

  // Fetch filter options
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const params = {
          ...(filters.org_id && { org_id: filters.org_id }),
          ...(filters.admin_id && { admin_id: filters.admin_id })
        };
        const response = await axios.get('/api/super/analytics/filters', { params });
        setFilterOptions(response.data);
      } catch (error) {
        setError("Error fetching filter options");
      }
    };
    fetchFilterOptions();
  }, [filters.org_id, filters.admin_id]);

  // Fetch questions and metrics
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { excluded_ips, ...apiParams } = filters;
        const response = await axios.get('/api/super/analytics/questions', { params: apiParams });
        setQuestions(response.data.questions);
         // Filter questions before processing metrics
        const filtered = getFilteredQuestions(response.data.questions, filters.excluded_ips);
        processMetrics(filtered);
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [filters]);

  // Get filtered questions for display
  const filteredQuestions = getFilteredQuestions(questions, filters.excluded_ips);

  // Process metrics from questions data
  const processMetrics = (questionData) => {
    const uniqueIPs = new Set(questionData.map(q => q.ipaddress));
    
    // Initialize metrics object
    const metrics = {
      total: questionData.length,
      syllabusQuestions: questionData.filter(q => q.is_syllabus).length,
      followUps: questionData.filter(q => q.is_follow_up).length,
      uniqueIPs: uniqueIPs.size,
      languages: {},
      weeklyDistribution: {}
    };

    // Get the start of the current year
    const currentYear = new Date().getFullYear();
    const startOfYear = new Date(currentYear, 0, 1);

    // Initialize all weeks of the year with 0
    for (let week = 1; week <= 53; week++) {
      metrics.weeklyDistribution[week] = 0;
    }

    // Calculate weekly distribution
    questionData.forEach(q => {
      const questionDate = new Date(q.timestamp);
      // Only process questions from current year
      if (questionDate.getFullYear() === currentYear) {
        // Calculate week number (1-53)
        const weekNumber = getWeekNumber(questionDate);
        metrics.weeklyDistribution[weekNumber] = (metrics.weeklyDistribution[weekNumber] || 0) + 1;
      }
    });

    setMetrics(metrics);
  };

  // Helper function to get week number
  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  // Helper function to get the start and end dates of a week
  const getWeekRange = (weekNum) => {
    const currentYear = new Date().getFullYear();
    const firstDayOfYear = new Date(currentYear, 0, 1);
    
    // Adjust first day to previous Sunday if necessary
    const dayOffset = firstDayOfYear.getDay();
    const firstWeekStart = new Date(firstDayOfYear);
    firstWeekStart.setDate(firstWeekStart.getDate() - dayOffset);
    
    // Calculate start date for the given week
    const weekStart = new Date(firstWeekStart);
    weekStart.setDate(weekStart.getDate() + (weekNum - 1) * 7);
    
    // Calculate end date (6 days after start)
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 6);
    
    return { start: weekStart, end: weekEnd };
  };

  // Helper function to format date range label
  const formatWeekLabel = (week) => {
    const { start, end } = getWeekRange(week);
    const formatDate = (date) => {
      const month = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      return `${month} ${day}`;
    };
    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  return (
    <Container>
      <Box sx={{ p: 2 }}>
        {/* Header */}
        <Typography level="h1" sx={{ mb: 4 }}>Analytics Dashboard</Typography>

        {/* Error Alert */}
        {error && (
          <Alert color="danger" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {/* Filters Section */}
        <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
          <Typography level="h4" sx={{ mb: 2 }}>Filters</Typography>
          <Grid container spacing={2}>
            <Grid xs={12} sm={4}>
              <FormControl>
                <FormLabel>Organization</FormLabel>
                <Select
                  value={filters.org_id}
                  onChange={(_, newValue) => setFilters(prev => ({
                    ...prev, 
                    org_id: newValue, 
                    admin_id: '', 
                    course_id: '' 
                  }))}
                >
                  <Option key="org-all" value="">All Organizations</Option>
                  {filterOptions.organizations.map(org => (
                    <Option 
                      key={`org-${org.id}`} 
                      value={org.id.toString()} 
                      id={`org-option-${org.id}`}
                    >
                      {org.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4}>
              <FormControl>
                <FormLabel>Admin</FormLabel>
                <Select
                  value={filters.admin_id}
                  onChange={(_, newValue) => setFilters(prev => ({  
                    ...prev, 
                    admin_id: newValue, 
                    course_id: '' 
                  }))}
                  disabled={!filters.org_id}
                >
                  <Option key="admin-all" value="">All Admins</Option>
                  {filterOptions.admins
                    .filter(admin => !filters.org_id || admin.org_id.toString() === filters.org_id)
                    .map(admin => (
                      <Option key={`admin-${admin.id}`} value={admin.id.toString()} id={`admin-option-${admin.id}`}>{admin.name}</Option>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4}>
              <FormControl>
                <FormLabel>Course</FormLabel>
                <Select
                  value={filters.course_id}
                  onChange={(_, newValue) => setFilters(prev => ({ 
                    ...prev, 
                    course_id: newValue 
                  }))}
                  disabled={!filters.org_id}
                >
                  <Option key="courses-all" value="">All Courses</Option>
                  {filterOptions.courses
                    .filter(course => !filters.org_id || course.org_id.toString() === filters.org_id)
                    .map((course,index) => (
                      <Option key={`course-${course.org_id}-${course.id}-${index}`} value={course.id.toString()} id={`course-option-${course.org_id}-${course.id}`}>{course.name}</Option>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <FormControl>
                <FormLabel>Exclude IP Addresses</FormLabel>
                <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                  <Input
                    placeholder="Enter IP address to exclude"
                    value={newIpAddress}
                    onChange={(e) => setNewIpAddress(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleAddIpAddress();
                      }
                    }}
                    sx={{ flex: 1 }}
                  />
                  <Button onClick={handleAddIpAddress}>Add</Button>
                </Stack>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {filters.excluded_ips.map((ip) => (
                    <Chip
                      key={ip}
                      endDecorator={<ChipDelete onDelete={() => handleRemoveIpAddress(ip)} />}
                      size="md"
                    >
                      {ip}
                    </Chip>
                  ))}
                </Box>
              </FormControl>
            </Grid>
          </Grid>
        </Card>

        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            {/* Metrics Overview */}
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {[
                {
                  id: 'total',
                  title: 'Total Questions',
                  value: metrics?.total || 0
                },
                {
                  id: 'syllabus',
                  title: 'Syllabus Questions',
                  value: metrics?.syllabusQuestions || 0,
                  percentage: metrics ? ((metrics.syllabusQuestions / metrics.total) * 100).toFixed(1) : 0
                },
                {
                  id: 'followups',
                  title: 'Follow-ups',
                  value: metrics?.followUps || 0,
                  percentage: metrics ? ((metrics.followUps / metrics.total) * 100).toFixed(1) : 0
                },
                {
                  id: 'unique-ips',
                  title: 'Unique IPs',
                  value: metrics?.uniqueIPs || 0
                }
              ].map((metric) => (
              <Grid key={metric.id} xs={12} md={3}>
                <Card variant="soft">
                  <Typography level="h4">{metric.title}</Typography>
                  {metric.percentage !== undefined ? (
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Typography level="h2">{metric.value}</Typography>
                      <Typography level="body-md" sx={{ color: 'neutral.500' }}>
                        {metric.percentage}%
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography level="h2">{metric.value}</Typography>
                  )}
                </Card>
              </Grid>
              ))}
            </Grid>

            {/* Weekly Distribution Chart */}
            {metrics?.weeklyDistribution && (
              <Card variant="outlined" sx={{ mb: 4, height: 400, p: 2 }}>
                <Typography level="h4" sx={{ mb: 2 }}>Questions per Week ({new Date().getFullYear()})</Typography>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart 
                    data={Object.entries(metrics.weeklyDistribution)
                    .sort(([weekA], [weekB]) => parseInt(weekA) - parseInt(weekB))
                    .map(([week, count]) => {
                      const weekNum = parseInt(week);
                      return {
                        id: `week-${weekNum}`, // Add unique id for React key
                        week: weekNum,
                        count: count,
                        dateRange: formatWeekLabel(weekNum)
                      };
                    })}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 45, // Increased bottom margin for rotated labels
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="dateRange"
                      interval={4}  // Show every 4th week
                      angle={-45}
                      textAnchor="end"
                      height={60} // Increased height for labels
                      tick={{ fontSize: 12 }}
                    />
                    <YAxis />
                    <RechartsTooltip 
                      formatter={(value, name) => [`${value} questions`, 'Volume']}
                      labelFormatter={(dateRange) => dateRange}
                      key={`tooltip-${new Date().getFullYear()}`}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="count" 
                      stroke="#8884d8" 
                      strokeWidth={2}
                      dot={false}
                      key={`line-${new Date().getFullYear()}`}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            )}
            <QuestionsList 
              filteredQuestions={filteredQuestions}
              questions={questions}
            />
          </>
        )}
      </Box>
    </Container>
  );
}