import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import {  
    Alert,
    Box,
    Button,
    FormControl,
    FormLabel,
    FormHelperText,
    Grid,
    Input,
    Stack,
    Typography,
} from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpIcon from '@mui/icons-material/HelpOutline';
import Container from '../components/Container';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';


export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [emailError, setEmailError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { login, isAuthenticated } = useContext(AuthContext);

    const validateEmail = () => {
      if (!email) {
        setEmailError('Email is required');
        return false;
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError('Email is invalid');
        return false;
      }
      setEmailError('');
      return true;
    };

    useEffect(() => {
        document.title = "Forgot Password - All Day TA";
        // Check if the user is already authenticated
        if (isAuthenticated) {
            navigate('/files', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail()) {
            return;
        }

        // Trim whitespace and convert email to lowercase
        const trimmedEmail = email.trim().toLowerCase();

        try {
            const response = await axios.post('/api/forgot_password', { email: trimmedEmail });
            if (response.data.accountExists) {
                setMessage('A password reset link has been sent to your email. Please check your spam folder.');
                setIsError(false);
            } else {
                setMessage('No account found with this email address.');
                setIsError(true);
            }
        } catch (error) {
            setMessage('An error occurred. Please try again later.');
            setIsError(true);
        }
    };

    const handleEmailChange = (e) => {
      setEmail(e.target.value);
      if (emailError) {
        setEmailError('');
      }
    };

    return (
        <Box>
            <header className="min-header-wrapper">
                <Box className="left-wrapper">
                    <a href="/login/admin" aria-label="Login"><img src={Logo} alt="All Day TA - Login" className="logo" /></a>
                    <a href="/login/admin" aria-label="Login" className="logo-mobile"><img src={LogoMobile} alt="All Day TA - Login" className="logo-mobile" /></a>
                </Box>
                <Box className="right-wrapper">
                    <Button
                        variant="plain"
                        component="a"
                        href="https://alldayta.com/support"
                        startDecorator={<HelpIcon />}
                        >
                        Contact Support
                    </Button>
                </Box>
            </header>
            <Container>
                <Grid container xs={12} mx='auto' spacing={2} justifyContent="space-around"
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '60vh', // Ensure full viewport height
                }}>
                    <Grid container xs={12} sm={4}>
                        <Stack
                            direction="column"
                            alignItems="left"
                            spacing={3}
                            sx={{ width:'100%' }}
                            >
                            <Box>
                                <Button component="a" href="/login/admin" startDecorator={<ArrowBackIcon/>} size="sm" variant="plain">Return to Login</Button>
                            </Box>
                            <Box>
                                <Typography level="h1" mb={1}>Forgot Password</Typography>
                                <Typography mt={2} mb={1}>Enter your email to receive a reset password link</Typography>
                            </Box>
                            {message && (
                                <Alert
                                    color={isError ? 'danger' : 'success'}
                                    sx={{ mb: 2 }}
                                    role="alert"
                                    aria-live="polite"
                                    >
                                    {message}
                                </Alert>
                            )}
                            <form onSubmit={handleSubmit}>
                            <FormControl error={!!emailError}>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    variant="outlined"
                                    color={emailError ? "danger" : "primary"}
                                    fullWidth
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                {emailError && <FormHelperText>{emailError}</FormHelperText>}
                            </FormControl>
                            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={2}>
                                <Button type="submit">Reset Password</Button>
                            </Stack>
                            </form>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}