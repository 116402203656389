import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Stack,
  Typography,
} from '@mui/joy';

const StudentAuth = ({ onAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      const response = await axios.post('/api/verify_student', {
        email,
        course_url: window.location.pathname
      });

      if (response.data.verified) {
        localStorage.setItem('student_email', email);
        onAuthenticated(email, response.data.user_id);
      } else {
        setError('You are not authorized to access this course. Please contact your professor.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
      <Grid container xs={12} mx='auto' spacing={2} justifyContent="space-around"
          sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh', // Ensure full viewport height
      }}>
          <Grid container xs={12} sm={3}>
              <Stack
                  direction="column"
                  alignItems="left"
                  spacing={3}
                  sx={{ width:'100%' }}
                  role="group"
                  aria-labelledby="student-validation"
                  >
                <Typography level="h2" id="student-validation">Student Email Required</Typography>
                <Typography>
                  Please enter your email address to access the All Day TA for this course.
                </Typography>
                <FormControl error={!!error}>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    color="primary"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError('');
                    }}
                    onKeyPress={handleKeyPress}
                  />
                  {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                    <Button loading={isSubmitting} onClick={handleSubmit}>Login</Button>
                </Stack>
            </Stack>
        </Grid>
      </Grid>
  );
};

export default StudentAuth;