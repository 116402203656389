import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import MediaQuery from "react-responsive";
import axios from 'axios';
import {
    Accordion,
    AccordionGroup,
    AccordionSummary,
    AccordionDetails,
    Box,
    Button,
    Card,
    FormControl,
    FormLabel,
    FormHelperText,
    Grid,
    List,
    ListItem,
    Snackbar,
    Stack,
    Textarea,
    Typography
} from '@mui/joy';
import QuestionAnswerCard from './QuestionAnswerCard';
import StudentAuth from './StudentAuth';
import Container from './Container';
import HelpIcon from '@mui/icons-material/HelpOutline';
import LogoIcon from '../assets/logoMobile.svg';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';
import LoadingIcon from '../assets/LoadingLogo.gif';

const StudentChatbot = forwardRef((props, ref) => {
    const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
    const [query, setQuery] = useState('');
    const [priorQAndResponse, setPriorQAndResponse] = useState('');
    const [isInitializing, setIsInitializing] = useState(true);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [index, setIndex] = useState(0);
    const [error, setError] = useState(null);
    const [errorText, setErrorText] = useState('');
    const [orgId, setOrgId] = useState(null);
    const [courseId, setCourseId] = useState(null); 
    const maxLength = 500;
    const textareaRef = useRef(null);
    const announcerRef = useRef(null);
    const location = useLocation();
    const courseUrl = location.pathname;
    const [courseName, setCourseName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    //Student Authentication
    const [requiresAuth, setRequiresAuth] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [studentEmail, setStudentEmail] = useState('');

    useEffect(() => {
        document.title = "Ask a Question - All Day TA";
    }, []);

    useEffect(() => {
        const initializeData = async () => {
            try {
                // First get course settings to check auth requirements
                const settingsResponse = await axios.get('/api/course_settings', {
                    params: { course_url: courseUrl }
                });
                
                console.log('Course settings:', settingsResponse.data);
                setRequiresAuth(settingsResponse.data.auth_type === 'email');

                // Check for saved authentication
                const savedEmail = localStorage.getItem('student_email');
                if (savedEmail) {
                    try {
                        const verifyResponse = await axios.post('/api/verify_student', {
                            email: savedEmail,
                            course_url: courseUrl
                        });
                        
                        if (verifyResponse.data.verified) {
                            setStudentEmail(savedEmail);
                            setIsAuthenticated(true);
                        } else {
                            localStorage.removeItem('student_email');
                        }
                    } catch (error) {
                        console.error('Error verifying saved email:', error);
                        localStorage.removeItem('student_email');
                    }
                }

                // Then initialize chatbot data
                const chatbotResponse = await axios.post('/api/initialize_chatbot', {
                    'course_url': courseUrl
                });

                if (chatbotResponse.status === 200) {
                    setIsInitializing(false);
                    setOrgId(chatbotResponse.data.org_id);
                    setCourseId(chatbotResponse.data.course_id);
                    setCourseName(chatbotResponse.data.course_name || 'Unknown Course');
                }
            } catch (error) {
                console.error('Initialization error:', error);
                setIsInitializing(false);
                if (error.response && error.response.status === 404) {
                    setError('course_not_found');
                } else {
                    setError('general_error');
                }
            }
        };

        initializeData();
    }, [courseUrl]);

    const CourseNameDisplay = ({ courseName }) => (
        <Box sx={{maxWidth:'340px'}}>
        <Typography level="h1" color="primary" sx={{ fontSize:'1.125rem', fontWeight:'500'}}>
            {courseName}
        </Typography>
        </Box>
    );

    const HelpfulTips = () => (
        <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems:'flex-start'}}>
            <Grid>
                <img
                    src={LogoIcon}
                    alt=""
                    style={{
                        width: '3rem',
                        height: '3rem',
                        display: 'block',
                        marginLeft: '1.5rem',
                    }}
                />
            </Grid>
            <Grid xs sx={{ height: 'auto', flexGrow: '1' }}>
                <Typography level="h2" mb={2}>Hello! I'm an AI assistant for {courseName}.</Typography>
                <Typography level="body-lg" mb={2}>Your professor has trained me to answer questions about anything you'd like to know about class concepts, logistics, and topics. I'll be here all day, all night, and all term.</Typography>
                <Typography level="title-lg">Helpful Tips</Typography>
                  <List component="ul" marker="disc" size="lg" sx={{ "--ListItem-minHeight": "36px" }}>
                    <ListItem>Ask your question in the language you are most comfortable in and I will answer in that language.</ListItem>
                    <ListItem>I do my best, but just like your friend who always gets an A, I'm not perfect.</ListItem>
                    <ListItem>If something seems unclear, ask me again in another way and I'll try to explain.</ListItem>
                  </List>
            </Grid>
        </Grid>
    );

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [query]);

    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && !event.shiftKey && query.trim() !== '') {
        event.preventDefault();
        // Announce that the question is being processed
        if (announcerRef.current) {
            announcerRef.current.textContent = "Processing your question. Please wait for the answer.";
        }

        // Use setTimeout to delay the handleAskQuestion call
        setTimeout(() => {
            handleAskQuestion();
        }, 50);
       }
    };

    // Handle authentication success
    const handleAuthenticated = (email) => {
        console.log('Authentication successful for email:', email);
        setIsAuthenticated(true);
        setStudentEmail(email);
    };

    const handleAskQuestion = async () => {
        if (requiresAuth && !isAuthenticated) {
            setErrorText('Please login to ask questions.');
            return;
        }

        if (query.trim() === '') {
            setErrorText('Please enter a question before submitting.');
            return;
        }
        setErrorText('');

        const currentQuery = query; // Store the current query
        setQuery(''); // Clear the form immediately
        setIsFirstLoad(false);

        if (announcerRef.current) {
            announcerRef.current.textContent = "Processing your question. Please wait for the answer.";
        }

        // Update priorQAndResponse with the last Q&A pair
        if (questionsAndAnswers.length > 0) {
            const lastQA = questionsAndAnswers[0]; // Get the most recent Q&A
            setPriorQAndResponse(JSON.stringify({
                question: lastQA.question,
                answer: lastQA.answer
            }));
        }

        const newQuestion = {
            id: questionsAndAnswers.length + 1,
            question: currentQuery, // Use the stored query
            answer: 'Processing...',
            isLoading: true,
            hasAnswer: true,
            question_log_org_id: -1, // set this as -1 while processing and update based on response provided in /api/chatbot_query
            question_log_course_id: -1, // set this as -1 while processing and update based on response provided in /api/chatbot_query
            question_log_question_id: -1, // set this as -1 while processing and update based on response provided in /api/chatbot_query
            question_log_user_id: -1 // set this as -1 while processing and update based on response provided in /api/chatbot_query
        };

        setQuestionsAndAnswers([newQuestion, ...questionsAndAnswers]);

        try {
            /*console.log("Submitting to LLM:", {
                query: currentQuery,
                prior_q_and_response: priorQAndResponse ? JSON.parse(priorQAndResponse) : null,
                course_id: courseId 
            });*/

            const response = await axios.post('/api/chatbot_query', {
                query: currentQuery, // Use the stored query
                prior_q_and_response: priorQAndResponse,
                org_id: orgId,
                course_id: courseId,
                student_email: studentEmail
            });

            const [newq_org_id, newq_course_id, newq_question_id, newq_user_id] = response.data.question_log_identifiers
            const jsonResponse = JSON.parse(response.data.response);

            const updatedQuestion = {
                ...newQuestion,
                answer: jsonResponse.answer,
                footnotes: jsonResponse.footnotes,
                isLoading: false,
                question_log_org_id: newq_org_id,
                question_log_course_id: newq_course_id,
                question_log_question_id: newq_question_id,
                question_log_user_id: newq_user_id
            };

            setQuestionsAndAnswers(prevState =>
                prevState.map(qa =>
                    qa.id === newQuestion.id ? updatedQuestion : qa
                )
            );

            setPriorQAndResponse(JSON.stringify({
                question: currentQuery,
                answer: jsonResponse.answer
            }));
        } catch (error) {
            //console.error('Error fetching chatbot response:', error);
            const updatedQuestion = {
                ...newQuestion,
                answer: 'An error occurred. Please try again later.',
                isLoading: false
            };
            setQuestionsAndAnswers(prevState =>
                prevState.map(qa =>
                    qa.id === newQuestion.id ? updatedQuestion : qa
                )
            );
        }
    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setErrorText('');
        if (inputValue.length <= maxLength) {
            setQuery(inputValue);
        } else {
            setQuery(inputValue.substring(0, maxLength));
            setSnackbarOpen(true);
        }
    }

    const handlePaste = (event) => {
        const pastedText = event.clipboardData.getData('text');
        const newText = query + pastedText;
        if (newText.length <= maxLength) {
            setQuery(newText);
        } else {
            setSnackbarOpen(true);
            setQuery(newText.substring(0, maxLength));
        }
        event.preventDefault();
      };    

    if (isInitializing) {
        return (
            <Box role="alert" aria-live="polite">
                <header className="min-header-wrapper">
                    <Box className="left-wrapper">
                        <img src={Logo} alt="All Day TA" className="logo" />
                        <img src={LogoMobile} alt="All Day TA" className="logo-mobile" />
                    </Box>
                </header>
                <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems:'center', justifyContent: 'center'}}>
                    <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
                        <img src={LoadingIcon} alt="" style={{ width: '6rem', height: '6rem', display: 'block' }} />
                        <Typography level="title-lg">Loading All Day TA</Typography>
                    </Stack>
                </Grid>     
            </Box>
        )
    }

    if (error === 'course_not_found') {
        return (
            <Box role="alert" aria-live="polite">
                <header className="min-header-wrapper">
                    <Box className="left-wrapper">
                        <img src={Logo} alt="All Day TA" className="logo" />
                        <img src={LogoMobile} alt="All Day TA" className="logo-mobile" />
                    </Box>
                </header>
                <Container>
                    <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems:'center', justifyContent: 'center'}}>
                        <Stack direction="column" alignItems="center" justifyContent="center" gap={2} sx={{ textAlign:'center'}}>
                            <Typography level="h2">Course Not Found</Typography>
                            <Typography level="body-lg">We couldn't find a course associated with this URL. Please check the URL and try again.</Typography>
                            <Button
                                variant="outlined"
                                component="a"
                                href="https://alldayta.com/support"
                                startDecorator={<HelpIcon />}
                                >
                                Contact Support
                            </Button>
                        </Stack>
                    </Grid>
                </Container>
            </Box>
        )
    }

    if (error === 'general_error') {
        return (
            <Box role="alert" aria-live="polite">
                <header className="min-header-wrapper">
                    <Box className="left-wrapper">
                        <img src={Logo} alt="All Day TA" className="logo" />
                        <img src={LogoMobile} alt="All Day TA" className="logo-mobile" />
                    </Box>
                </header>
                <Container>
                    <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems:'center', justifyContent: 'center'}}>
                        <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
                            <Typography level="h2">Error</Typography>
                            <Typography level="body-lg">An error occurred while loading the chatbot. Please try again later.</Typography>
                            <Button variant="solid" onClick={() => window.location.reload()}>
                                Retry
                            </Button>
                        </Stack>
                    </Grid>
                </Container>
            </Box>
        )
    }

    //Student login
    if (requiresAuth && !isAuthenticated) {
      return (
        <Box>
          <header className="min-header-wrapper">
            <Box className="left-wrapper student-left-wrapper">
                <img src={Logo} alt="All Day TA" className="logo" />
                <img src={LogoMobile} alt="All Day TA" className="logo-mobile" />
                <CourseNameDisplay courseName={courseName} />
            </Box>
          </header>
          <StudentAuth onAuthenticated={handleAuthenticated}/>
        </Box>
      );
    }

    return (
        <Box>
            <header className="header-wrapper">
                <MediaQuery minWidth={541}>
                    <Box className="left-wrapper student-left-wrapper">
                        <img src={Logo} alt="All Day TA" className="logo" />
                        <img src={LogoMobile} alt="All Day TA" className="logo-mobile" />
                        <CourseNameDisplay courseName={courseName} />
                    </Box>
                    <Box className="right-wrapper">
                        <Button
                            variant="plain"
                            component="a"
                            target="_blank"
                            href="https://alldayta.com/student-faqs"
                            startDecorator={<HelpIcon />}
                            >
                            Help
                        </Button>
                    </Box>
                </MediaQuery>
                <MediaQuery maxWidth={540}>
                    <Box className="left-wrapper">
                        <img src={LogoMobile} alt="All Day TA" className="logo-mobile" />
                        <Button
                            variant="plain"
                            component="a"
                            target="_blank"
                            href="https://alldayta.com/student-faqs"
                            startDecorator={<HelpIcon />}
                            >
                            Help
                        </Button>
                    </Box>
                    <Box className="right-wrapper">
                        <CourseNameDisplay courseName={courseName} />
                    </Box>
                </MediaQuery>
            </header>
            <Container>
                <Grid container direction="row" justifyContent="center" sx={{ flex: 1, overflowY: 'auto', paddingBottom: '3rem' }}>
                    <Grid xs={12} sm={12} md={8}>
                        <Stack direction="column" spacing={3} alignItems="center">
                            {isFirstLoad && <HelpfulTips />}
                            {questionsAndAnswers.slice().reverse().map((qa) => (
                                <QuestionAnswerCard
                                    key={qa.id}
                                    id={qa.id}
                                    question={qa.question}
                                    answer={qa.answer}
                                    footnotes={qa.footnotes}
                                    isLoading={qa.isLoading}
                                    orgId={qa.question_log_org_id}
                                    courseId={qa.question_log_course_id}
                                    questionId={qa.question_log_question_id}
                                    userId={qa.question_log_user_id}
                                />
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" sx={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                    <Grid xs={12} sm={12} md={8}>
                        <Card variant="soft" orientation="vertical" sx={{ boxSizing: 'border-box', boxShadow: 'sm' }}>
                            <FormControl>
                                <FormLabel>Ask a question</FormLabel>
                                <Textarea
                                    name="outlined"
                                    color="primary"
                                    value={query}
                                    onChange={handleInputChange}
                                    onPaste={handlePaste}
                                    onKeyPress={handleKeyPress}
                                    ref={textareaRef}
                                    sx={{
                                        minHeight: '4rem',
                                        marginBottom: '0rem',
                                        overflow: 'hidden',
                                        resize: 'none',
                                    }}
                                />
                                {errorText && <FormHelperText>{errorText}</FormHelperText>}
                            </FormControl>
                            <Stack direction="row" justifyContent="space-between" alignItems="center"spacing={2}>
                                {/*<Button
                                    value="md"
                                    variant="outlined"
                                    endDecorator={<ArrowForwardIcon />}
                                    color="neutral"
                                >
                                    Ask as follow up
                                </Button>*/}
                                <Typography level="component-sm">
                                    {query.length}/{maxLength} characters
                                </Typography>
                                <Button variant="solid" size="md" onClick={handleAskQuestion} aria-label={`Ask question: ${query}`}>
                                    Ask
                                </Button>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
                <div 
                    ref={announcerRef}
                    aria-live="polite"
                    aria-atomic="true"
                    style={{ position: 'absolute', height: '1px', width: '1px', overflow: 'hidden', clip: 'rect(1px, 1px, 1px, 1px)' }}
                />
                <Snackbar
                    autoHideDuration={8000}
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    color="success"
                    variant="soft"
                    role="alert"
                    aria-live="polite"
                    tabIndex={-1}
                >
                    You have exceeded 500 characters! Your text has been truncated
                </Snackbar>
            </Container>
        </Box>
    );
});

StudentChatbot.isStudentChatbot = true;

export default StudentChatbot;
