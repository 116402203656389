import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
  Input,
  Link,
  List,
  ListItem,
  ListDivider,
  Stack,
  Typography,
} from '@mui/joy';
import Container from '../components/Container';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import universitiesData from '../assets/world_universities_and_domains.json';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';

export default function ProfessorSignUp() {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [professorOrg, setProfessorOrg] = useState('');
  const [professorName, setProfessorName] = useState('');
  const [professorEmail, setProfessorEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({
    university: false,
    professorName: false,
    professorEmail: false,
    terms:false,
  });

  // Priority countries that should appear at the top
  const priorityCountries = ['United States', 'Canada', 'Australia'];

  // Get unique list of countries from universities data
  const countries = React.useMemo(() => {
    const uniqueCountries = [...new Set(universitiesData.map(uni => uni.country))];
    const otherCountries = uniqueCountries
      .filter(country => !priorityCountries.includes(country))
      .sort();
    
    // Return all countries but keep track of which are priority
    return [...priorityCountries, ...otherCountries];
  }, []);

  // Filter universities based on selected country
  const filteredUniversities = selectedCountry && selectedCountry !== '──────────'
    ? universitiesData.filter(uni => uni.country === selectedCountry)
    : [];

  const handleProfessorNameChange = (e) => {
    setProfessorName(e.target.value);
    if (errors.professorName) {
      setErrors(prev => ({ ...prev, professorName: false }));
    }
  };

  const handleProfessorEmailChange = (e) => {
    setProfessorEmail(e.target.value);
    if (errors.professorEmail) {
      setErrors(prev => ({ ...prev, professorEmail: false }));
    }
  };

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
    if (errors.terms) {
      setErrors(prev => ({ ...prev, terms: false }));
    }
  };

  const handleSave = async () => {
    console.log('handleSave function called');
    setIsSubmitted(true);

    const newErrors = {
      university: !selectedUniversity,
      professorName: !professorName.trim(),
      professorEmail: !professorEmail.trim(),
      terms: !termsAccepted
    };

    setErrors(newErrors);

    // If there are any errors, stop the submission
    if (Object.values(newErrors).some(error => error)) {
      return;
    }

    // Trim whitespace and convert email to lowercase
    const trimmedEmail = professorEmail.trim().toLowerCase();

    // Create/verify organization first, then create admin
    try {
      // First, create or verify the organization
      const orgData = {
        orgName: selectedUniversity.name,
        orgContact: professorName.trim(), // Using professor as initial contact
        orgEmail: professorEmail.trim().toLowerCase() // Using professor email as initial contact
      };

      const orgFormData = new FormData();
      orgFormData.append('orgData', JSON.stringify(orgData));

      // This will either create a new org or return the existing one
      const orgResponse = await axios.post('/api/create_org', orgFormData);
      const organizationId = orgResponse.data.org.org_id;

      const adminData = {
        professorName: professorName.trim(),
        university: selectedUniversity.name,
        country: selectedUniversity.country,
        professorEmail: trimmedEmail,
        professorOrg: organizationId
      };
      console.log('adminData prepared:', adminData);
    
      const adminFormData = new FormData();
      adminFormData.append('adminData', JSON.stringify(adminData));
      console.log('adminFormData created:', adminFormData);
    
      const adminResponse = await axios.post('/api/create_admin', adminFormData);

      if (adminResponse.status === 200) {
        setMessage('Account created successfully');
        setIsError(false);
    
        // Clear the form
        setSelectedCountry(null);
        setSelectedUniversity(null);
        setProfessorName('');
        setProfessorEmail('');
        setTermsAccepted(false);
      }  
    } catch (error) {
      setMessage('Error creating admin: ' + error.response.data.error);
      setIsError(true);
      console.error('Error details:', error);
    }
  };

  return (
    <Box>
      <header className="header-wrapper">
        <Box className="left-wrapper">
            <a href="/login/admin"><img src={Logo} alt="All Day TA" className="logo" /></a>
            <a href="/login/admin"><img src={LogoMobile} alt="All Day TA" className="logo-mobile" /></a>
        </Box>
      </header>
      <Container>
        <Grid container xs={12} mx='auto' spacing={4} justifyContent="space-around"
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
            height: '60vh', // Ensure full viewport height
          }}>
            <Grid xs={12} sm={8} md={6} sx={{ display: 'flex', alignItems: 'stretch' }}>
              <Stack direction="column" spacing={3} sx={{ width: '100%' }}>
              <Typography level="h1">Create your All Day TA account</Typography>
              {message && (
                  <Alert
                      color={isError ? 'danger' : 'success'} 
                      sx={{ mb: 2 }}
                      role="alert"
                      aria-live="polite"
                      >
                      {message}
                  </Alert>
              )}
              <FormControl>
                <FormLabel>Country</FormLabel>
                <Autocomplete
                  variant="outlined"
                  color="primary"
                  value={selectedCountry}
                  onChange={(event, newValue) => {
                    setSelectedCountry(newValue);
                    setSelectedUniversity(null); // Reset university when country changes
                  }}
                  options={countries}
                  placeholder="Select a country"
                  slotProps={{
                    listbox: {
                      sx: { maxHeight: 200, overflow: 'auto' }
                    }
                  }}
                  // Style the separator differently
                  renderOption={(props, option, { selected, highlighted }) => {
                    const isPriority = priorityCountries.includes(option);
                    return (
                      <React.Fragment key={option}>
                        {option === priorityCountries[priorityCountries.length - 1] && (
                          <>
                            <ListItem
                              {...props}
                              role="option"
                              aria-selected={selected}
                              selected={selected}
                              variant={highlighted ? 'soft' : 'plain'}
                              color={highlighted ? 'primary' : undefined}
                              sx={{
                                fontWeight: isPriority ? 500 : 400,
                                '&.Mui-focusVisible': {
                                  outlineWidth: '2px',
                                  outlineStyle: 'solid',
                                  outlineColor: 'var(--joy-palette-primary-500)',
                                  outlineOffset: '-2px'
                                }
                              }}
                            >
                              {option}
                            </ListItem>
                            <ListDivider />
                          </>
                        )}
                        {option !== priorityCountries[priorityCountries.length - 1] && (
                          <ListItem
                            {...props}
                            role="option"
                            aria-selected={selected}
                            selected={selected}
                            variant={highlighted ? 'soft' : 'plain'}
                            color={highlighted ? 'primary' : undefined}
                            sx={{
                              fontWeight: isPriority ? 500 : 400,
                              '&.Mui-focusVisible': {
                                outlineWidth: '2px',
                                outlineStyle: 'solid',
                                outlineColor: 'var(--joy-palette-primary-500)',
                                outlineOffset: '-2px'
                              }
                            }}
                          >
                            {option}
                          </ListItem>
                        )}
                      </React.Fragment>
                    );
                  }}
                />
                <FormHelperText>
                  Select a country to filter universities & colleges
                </FormHelperText>
              </FormControl>
              <FormControl error={errors.university}>
                <FormLabel>University or College</FormLabel>
                <Autocomplete
                  variant="outlined"
                  color="primary"
                  value={selectedUniversity}
                  onChange={(event, newValue) => {
                    setSelectedUniversity(newValue);
                    if (errors.university) {
                      setErrors(prev => ({ ...prev, university: false }));
                    }
                  }}
                  options={filteredUniversities}
                  getOptionLabel={(option) => option.name}
                  placeholder="Select your university or college"
                  disabled={!selectedCountry}
                  slotProps={{
                    listbox: {
                      sx: { maxHeight: 200, overflow: 'auto' }
                    }
                  }}
                />
                <FormHelperText>
                  {errors.university ? "University or college selection is required" : null }
                </FormHelperText>
              </FormControl>
              <FormControl error={errors.professorName}>
                <FormLabel>Name</FormLabel>
                <Input variant="outlined"
                  color=
                    {errors.professorName
                      ? "danger"
                      : "primary"
                      } 
                  required value={professorName} onChange={(e) => setProfessorName(e.target.value)} />
                  <FormHelperText>
                    {errors.professorName
                    ? "Professor name is required"
                    : null }
                  </FormHelperText>
              </FormControl>
              <FormControl error={errors.professorEmail}>
                <FormLabel>Email</FormLabel>
                <Input variant="outlined"
                  color=
                    {errors.professorEmail
                      ? "danger"
                      : "primary"
                      } 
                  required value={professorEmail} onChange={(e) => setProfessorEmail(e.target.value)} />
                <FormHelperText>
                    {errors.professorEmail
                    ? "Professor email is required"
                    : null }
                  </FormHelperText>
              </FormControl>

              <FormControl error={errors.terms}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.6rem'}}>
                    <Checkbox
                      color={errors.terms ? "danger" : "primary"}
                      checked={termsAccepted}
                      onChange={handleTermsChange}
                      aria-labelledby="terms-text"
                    />
                    <Typography id="terms-text">
                      I have read and agree to the{' '}
                      <Link 
                        href="https://www.alldayta.com/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="always"
                      >
                        terms & conditions
                      </Link>
                    </Typography>
                </Box>
                {errors.terms && (
                  <FormHelperText sx={{ color: 'var(--joy-palette-danger-500)' }}>
                    You must accept the terms and conditions to continue
                  </FormHelperText>
                )}
              </FormControl>
              <Stack direction="column" justifyContent="flex-end" alignItems="flex-end">
                <Button endDecorator={<ArrowForwardIcon/> } onClick={handleSave}>Sign Up</Button>
              </Stack>
              </Stack>
            </Grid>
            <Grid xs={12} sm={4} md={6} sx={{ display: 'flex', alignItems: 'stretch' }}>
              <Card 
                size='lg'
                variant='plain'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: 2, 
                  p: 5,
                  boxShadow: '0 2px 2px #ccc'
                }}
              >
                <Typography level="h2" color="primary">Try All Day TA free for two weeks</Typography>
                <Typography level="body-md">Give your students 24/7 access to an AI teaching assistant that instantly answers their questions – from basic concepts to complex problems.</Typography>
                <Box>
                  <Typography level="title-md">Included in your free trial:</Typography>
                  <List marker="disc">
                    <ListItem>Upload up to 10 course files (lectures, notes, handouts) to create your All Day TA in minutes</ListItem>
                    <ListItem>Share a unique course link with your students to start getting help immediately</ListItem>
                    <ListItem>Monitor student questions in the Summaries tab throughout your trial</ListItem>
                    <ListItem>Decide if All Day TA is right for your class before subscribing</ListItem>
                  </List>
                </Box>
                <Typography level="title-md">Start your free trial today by creating an account.</Typography>
              </Card>
            </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
