import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
  List,
  ListItem,
  Box
} from "@mui/joy";
import CreateIcon from "@mui/icons-material/Create";
import FileSelector from "./FileSelector";

export default function EditFileModal({
  open,
  onClose,
  file,
  setFiles,
  handleReplaceFile,
  handleUpdateTitle,
  refresh
}) {
  const [layout, setLayout] = useState(undefined);
  const [fileName, setFileName] = useState(file ? file.title : "");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileSelector, setShowFileSelector] = useState(true);

  useEffect(() => {
    if (file) {
      setFileName(file.title); // Sets the current file name as the fileName
    }
    if (open) {
      setSelectedFile(null); // Reset selected file when modal opens
      setShowFileSelector(true); // Reset showFileSelector when modal opens
    }
  }, [file, open]);

  // Only updates the title
  const handleChangeTitle = async () => {

    handleUpdateTitle(file.id, fileName); // Update file status in FileTable immediately
    onClose(); // Close the modal 

    try {
        const response = await axios.put(`/api/update_title/${file.org_id}/${file.course_id}/${file.file_id}/`, {
            new_title: fileName
        });

        if (response.status === 200) {
            //console.log(`Successfully updated the title with the new title ${fileName}`);
            refresh();
        } else {
            //console.error('Error replacing file:', response.data.status);
        }
    } catch (error) {
        //console.error('Error updating title:', error.response ? error.response.data.message : error.message);
    }
};

  const toggleFileSelector = () => {
    setShowFileSelector(!showFileSelector);
  };

  const handleFileReplace = async () => {
    if (!file || !selectedFile) return;
  
    handleReplaceFile(file.id, fileName); // Update file status in FileTable immediately
    onClose(); // Close the modal 

    try {
      const formData = new FormData();
        formData.append('file', selectedFile.file);
  
      if (fileName) {
        formData.append('fileTitle', fileName); // Add new file name if supplied
      }
  
      const response = await axios.post(`/api/replace_file/${file.org_id}/${file.course_id}/${file.file_id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        refresh();
        //console.log(`Successfully replaced the file ${file.title} with the new file`);
      }
    } catch (error) {
      //console.error('Error replacing file:', error);
    }
  };

  const handleSubmit = () => {
    if (!selectedFile) {
        //console.log("Only updating the title...")
        handleChangeTitle();
    } else {
        //console.log("Replacing the whole file and updating the title")
        handleFileReplace();
    }
  }
  
  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const handleFileSelected = (file) => {
    setSelectedFile(file);
    setShowFileSelector(false);
  };

  return (
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          layout="fullscreen"
          variant="outlined"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={12} justifyContent="flex-end">
              <ModalClose 
                aria-label="Close Dialog"
              />
            </Grid>
            <Grid xs={12}>
              <Typography level="h1" mb={3}>
                Edit File
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}
                mb={4}
              >
                <FormControl sx={{ width: '100%' }}>
                  <FormLabel>File Name</FormLabel>
                  <Input
                    variant="outlined"
                    color="primary"
                    onChange={handleFileNameChange}
                    fullWidth
                    value={fileName}
                    sx={{ width: "100%", marginBottom: "1.5rem" }}
                  />
                </FormControl>
                {showFileSelector ? (
                  <>
                  <Typography level="title-lg">
                    Upload an updated version of this file to replace it:
                  </Typography>
                  <FileSelector onFileSelected={handleFileSelected} />
                  </>
                ) : (
                <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={2}>
                    {selectedFile && (
                      <Typography level="body-md"><strong>Selected file:</strong> {selectedFile.title}</Typography>
                    )}
                    <Button onClick={toggleFileSelector} variant="outlined">
                      Select a different file
                    </Button>
                </Stack>
                )}
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} variant="solid">
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
  );
}
